import Vue from 'vue';
import store from '@/store';
import VueRouter from 'vue-router';
import { getRiskcontrol } from '@/api';
Vue.use(VueRouter);

const rank =() => import(/* webpackChunkName: "rankList" */ '@/views/page/list.html.vue');
const Honeycomb = () => import(/* webpackChunkName: "Honeycomb" */ '@/views/_trader/_code/_type.vue');
const NotFound = () => import(/* webpackChunkName: "404" */ '@/views/NotFound');
const download = () => import(/* webpackChunkName: "download" */ '@/views/download'); // 下载页
const Home = () => import(/* webpackChunkName: "download" */ '@/views/index'); // 下载页


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  // 解决重复报错的问题
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  // {
  //   path: '/tpl/:type',
  //   redirect: (to) => {
  //     console.log(to);
  //     const { params } = to;
  //     const { _type } = params;
  //     console.log('_type::: ', _type);
  //     return `/trader/1670082012/p001`;
  //   }
  // },
  { path: '/', name: 'Home', component: Home, meta: {} },
  { path: '/page/list.html', name: 'rank', component: rank, meta: {} },
  { path: '/tpl/:code/:type', name: 'tplCode', component: Honeycomb, meta: {} },
  { path: '/tpl/:type', name: 'tpl', component: Honeycomb, meta: {} },
  { path: '/:trader/:code/:type', name: 'honeycombCodeType', component: Honeycomb, meta: {} },
  { path: '/:trader/:code', name: 'honeycombCode', component: Honeycomb, meta: {} },
  { path: '/download', name: 'download', component: download, meta: {} },
  { path: '*', name: 'NotFound', component: NotFound, meta: { isLogin: true, level: '2,4,999' } },
];

const router = new VueRouter({
  mode: 'history',
  routes
});

// 前置

router.beforeEach(async (to, from, next) => {
  // 屏蔽点位
  const risk = await getRiskcontrol({}).catch(() => {
    console.log('riskcontrol');
  });
  store.commit('base/set_riskcontrol', risk || {});
  next();
});

router.afterEach(() => {});

export default router;
